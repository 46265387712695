$(function() {
    $('.js-sticky').sticky({
        topSpacing: 0,
        zIndex: 30

    });
    let navNodes = $('.js-models-slider-thumbs');
    $('.js-models-slider-big').each(function(i, node) {
        let listNodeUniqueClass = 'js-models-slider-big-' + i;
        $(node).addClass(listNodeUniqueClass);
        let navNodeUniqueClass = 'js-models-slider-thumbs-' + i;
        navNodes.eq(i).addClass(navNodeUniqueClass);

        let listSelector = '.' + listNodeUniqueClass;
        let navSelector = '.' + navNodeUniqueClass;

        $(listSelector).slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            nextArrow: '<div class="slick__arrow slick__arrow_next"><svg width="10" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 1L2 8.5 9 16" stroke="#fff"/></svg></div>',
            prevArrow: '<div class="slick__arrow slick__arrow_prev"><svg width="10" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 1L2 8.5 9 16" stroke="#fff"/></svg></div>',
            asNavFor: navSelector,
        });
        $(navSelector).slick({
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 5,
            dots: false,
            variableWidth: true,
            focusOnSelect: true,
            asNavFor: listSelector,

        });
    });
    $('.models__param').each(function(){
        $(this).find('.js-param').eq(0).addClass('active');
    });

    $(document).on('click', '.js-param', function(){
        $(this).closest('.models__param').find('.js-param').removeClass('active');
        $(this).addClass('active');
        var carid = $(this).closest('.models__item').attr('data-car-id'),
        engine = $(this).closest('.models__item').find('.js-param.active').attr('data-engine');
        param = $(this).closest('.models__item').find('.js-param[data-param].active').attr('data-param'),
        oldprice = $(this).closest('.models__item').find('.js-oldprice'),
        price = $(this).closest('.models__item').find('.js-price');

/*
        $.ajax({
            url: url,
            dataType: 'json',
            data: {
                "id": carid,
                "engine": engine,
                "param": param
            },
            success: function(result){
                oldprice.text(result.oldprice);
                price.text(result.price);
            }
        });*/


    })

})