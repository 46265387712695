$(function() {

    $('.js-diller-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '.js-diller-thumbs',
        nextArrow: '<div class="slick__arrow slick__arrow_next"><svg width="10" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 1L2 8.5 9 16" stroke="#fff"/></svg></div>',
        prevArrow: '<div class="slick__arrow slick__arrow_prev"><svg width="10" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 1L2 8.5 9 16" stroke="#fff"/></svg></div>',
    });
    $('.js-diller-thumbs').slick({
        slidesToScroll: 1,
        asNavFor: '.js-diller-slider',
        dots: false,
        variableWidth: true,
        focusOnSelect: true,
    });

})