$(function() {
	$(document).on('click', '.js-menu-btn', function(){
		$(this).toggleClass('active');
		$('html').toggleClass('o-hidden');
		$('.js-menu-content').fadeToggle();
	});
	$(document).on('click', '.js-open-models', function(){
		if($(document).width()>767){
		if($(this).hasClass('active')){
			$('.js-models-content').slideUp(150);
			$('.js-open-models').removeClass('active');
		}else{
			$(this).addClass('active');
			$('.js-models-content').slideDown(150);
		}
	}
	});
	$(document).click( function(e){
		if ( $(e.target).closest('.nav__item_dropdown').length ) {
			return;
		}
		if($(document).width()>767){
			$('.js-models-content').slideUp(150);
			$('.js-open-models').removeClass('active');
		}
	});
});