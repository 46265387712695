$(function() {
    $('.js-main-slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        nextArrow: '<div class="slick__arrow slick__arrow_next"><svg width="10" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 1L2 8.5 9 16" stroke="#fff"/></svg></div>',
        prevArrow: '<div class="slick__title"><div class="slick__arrow slick__arrow_prev"><svg width="10" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 1L2 8.5 9 16" stroke="#fff"/></svg></div><div class="slick__text js-slide-title"></div></div>',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots: true
                }
            },
        ],
    });
    setTimeout(function(){
        $('.js-slide-title').text($(document).find('.js-main-slider .slider__item').eq(1).attr('data-slide-name'))

    },200);
    $('.js-main-slider').on('init afterChange', function(e, slick){
        let currentSlick = $(document).find('.js-main-slider .slick-current.slick-active');
        if(!currentSlick.next().attr('data-slide-name')){
            var text = $(document).find('.js-main-slider .slider__item').eq(0).attr('data-slide-name');
        }else{
            var text = currentSlick.next().data('slide-name')

        }
        $('.js-slide-title').text(text)
    });
})