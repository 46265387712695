$(function() {

    $('.js-social-slider').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: '<div class="slick__arrow slick__arrow_next"><svg width="10" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 1L2 8.5 9 16" stroke="#fff"/></svg></div>',
        prevArrow: '<div class="slick__arrow slick__arrow_prev"><svg width="10" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 1L2 8.5 9 16" stroke="#fff"/></svg></div>',
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            }
        ],
    });

})