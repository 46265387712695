$(function() {
    //плавный скролл к якорю
	$(".js-scroll").click(function (event) {
		event.preventDefault();
		var id  = $(this).attr('data-href'),
			top = $(id).offset().top;
		$('body,html').animate({scrollTop: top - 61}, 800);
	});

    
    var calcStart = $('.js-calc-start');
    calcStart.ionRangeSlider({
        skin: "round",
        from: 40,
        min: 0,
        max: 80,
        step: 1,
        grid: true,
        grid_num: 4,
        grid_snap: false,
        onChange: function (data) {
            updateCalc();
        },
    });

    var calcTime = $('.js-calc-time');
    calcTime.ionRangeSlider({
        skin: "round",
        min: 12,
        max: 84,
        from: 48,
        step: 12,
        grid: true,
        grid_num: 6,
        grid_snap: false,
        onChange: function (data) {
            updateCalc();
        },
    });
    function updateCalc(){
        var sliderStart     = calcStart.data("ionRangeSlider"),
            startText       = $('.js-calc-start-text'),
            startSumm       = $('.js-calc-start-summ'),
            carName         = $('.js-calc-car-name')
            carPrice        = +$('.calc__model.active .js-calc-car-name').attr('data-price'),
            timeCountText   = $('.js-calc-time-count'),
            sliderTime      = calcTime.data("ionRangeSlider"),
            totalVal        = $('.js-calc-total'),
            modelsCountText = $('.js-calc-models-count'),
            creditPercent   = +$('.calc__stavka').attr('data-percent');

        startText.text(sliderStart.result.from+'%');
        startSumm.text(numberWithSpaces(Math.round(carPrice/100*(sliderStart.result.from)))+' руб.');
        timeCountText.text(sliderTime.result.from + ' ' +declOfNum(sliderTime.result.from, ['месяца', 'месяца', 'месяцев']));
        modelsCountText.text($('.calc__models-wrap .calc__model.active').index()+1 + ' / ' +$('.calc__models-wrap .calc__model').length);
        var i = creditPercent/100/12;
        var res = (carPrice - Math.round(carPrice/100*(sliderStart.result.from)))* (i + i / (Math.pow((1+i), sliderTime.result.from) - 1)) ;
        totalVal.text(numberWithSpaces(Math.round(res))+'');
    }
    if(calcTime.length>0){
        updateCalc();

    }

    /*pagination*/
    $('.js-calc-arrow-right').click(function(){
        var index = $('.calc__models-wrap .calc__model.active').index(),
            slides = $('.calc__models-wrap .calc__model').length-1;
        $('.calc__model').removeClass('active');
        if(index+1>slides){
            index = -1;
        }
        $('.calc__model').eq(index+1).addClass('active');
        calcChangeImg(index+1);
        updateCalc();
    });

    $('.js-calc-arrow-left').click(function(){
        var index = $('.calc__models-wrap .calc__model.active').index(),
            slides = $('.calc__models-wrap .calc__model').length-1;
        $('.calc__model').removeClass('active');   
        $('.calc__model').eq(index-1).addClass('active');
        calcChangeImg(index-1);
        updateCalc();
    });

    function calcChangeImg(index){
        if($('.calc__cars img').length>1){
            $('.calc__cars img').removeClass('active');
            $('.calc__cars img').eq(index).addClass('active');
        }
    }

    /* end pagination*/
    function declOfNum(number, titles) {  
        cases = [2, 0, 1, 1, 1, 2];  
        return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
    }





    function numberWithSpaces(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

});